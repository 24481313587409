function openProductCategoriesWindow() {
	document.getElementById('product-category-column-overlay').style.display = 'block';
}

function hideProductCategoryPopUp() {
	document.getElementById('product-category-column-overlay').style.display = '';
}

/**
 * Allow commodity menus to expand when they are used
 *
 * @param buttonEle
 * @param targetName
 */
function toggleCommodityGroup(buttonEle, targetName) {
	var target = document.getElementById(targetName);
	var computedMaxHeight = window.getComputedStyle(target).getPropertyValue('max-height');
	if(computedMaxHeight === 0 || computedMaxHeight === '0px' || computedMaxHeight === '' || typeof computedMaxHeight === 'undefined') {
		target.style.maxHeight = '1500px';
		buttonEle.getElementsByClassName('expand-icon')[0].innerHTML = '&#x25B2;';
	} else {
		target.style.maxHeight = 0;
		buttonEle.getElementsByClassName('expand-icon')[0].innerHTML = '&#x25BC;';

	}
}