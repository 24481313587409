
function pullLoginBanner() {
	if (document.getElementById('login-popup-banner')) {
		var args = "type=POPUPBANNER&id=4";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updateLoginBanner, "json");
	}
}

function updateLoginBanner(response) {
	let target = document.getElementById('login-popup-banner');
	let key = 0;

	if (response.length > 0 && typeof response[0].id !== 'undefined' && target !== null) {
		response.forEach((r) => {
			let wrapper = document.createElement('div');
			wrapper.id = 'login-popup-banner-entry-' + key;
			wrapper.className = 'login-popup-banner-entry';
			if (key !== 0) {
				wrapper.style.display = 'none';
			}
			key++;
			let link = null;
			let button = null;

			if (r.clickable === true) {
				link = document.createElement('a');
				link.setAttribute('href', r.actionURL);

				// I know it says button but trust me
				button = document.createElement('div');
				button.className = 'link-button';
				button.textContent = 'Read More';
			}

			let image = document.createElement('img');
			image.setAttribute('src', r.sourceURL);
			image.setAttribute('alt', r.title);


			if (r.clickable === true) {
				link.append(image);
				link.append(button);
				wrapper.append(link);
			} else {
				wrapper.append(image);
			}
			target.append(wrapper);
		});

		document.getElementById('login-popup-page-overlay').style.display = 'block';


		setTimeout(animateLoginBanner, 4000);
	}
}

function animateLoginBanner() {
	let wrapper = document.getElementById('login-popup-banner');
	let bannerEntries = wrapper.getElementsByClassName('login-popup-banner-entry');
	let currentActiveBanner = null;
	[].forEach.call(bannerEntries, function(e) {
		if (e.style.display !== 'none') {
			currentActiveBanner = e;
		}
	});

	let key = parseInt(currentActiveBanner.id.slice(currentActiveBanner.id.length - 1));
	let newKey = 0;

	if (bannerEntries.length <= 1) {
		// Nothing to animate
		return;
	}

	if (key < bannerEntries.length - 1) {
		newKey = key + 1;
		// Otherwise just leave at 0
	}

	let nextDiv = document.getElementById('login-popup-banner-entry-' + newKey);
	let currentDiv = document.getElementById('login-popup-banner-entry-' + key);

	wrapper.insertBefore(nextDiv, currentDiv);


	nextDiv.style.display = 'block';
	currentDiv.className += ' fade-out';
	setTimeout(function() {
		currentDiv.style.display = 'none';
		currentDiv.className = document.getElementById('login-popup-banner-entry-' + key).className.replace(' fade-out', '');
	}, 500);

	setTimeout(animateLoginBanner, 4000);
}

function closeLoginPopupDialog() {
	document.getElementById('login-popup-page-overlay').style.display = 'none';
}

function pullCMSContent() {
	if (document.getElementById('wrapper-carousel-entry')) {
		var args = "type=MAINBANNER&id=1";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updateCarouselBanner, "json");
	}
}

function updateCarouselBanner(response) {
	let target = document.getElementById("wrapper-carousel-entry");

	if (target !== null) {
		let preLabels = [];
		let postLabels = [];
		let radios = [];
		let entries = [];
		let count = 0;
		response.forEach(output => {
			let label = document.createElement('label');
			label.htmlFor = 'carousel-' + count;
			preLabels.push(label);
			label.className = 'carousel-button carousel-button-' + count;
			postLabels.push(label);

			let radio = document.createElement('input');
			radio.setAttribute('type', 'radio');
			radio.setAttribute('name', 'carousel-radio');
			radio.id = 'carousel-' + count;
			if (entries === 0) {
				radio.checked = true
			}
			radios.push(radio);

			let div = document.createElement('div');
			div.id = 'carousel-entry-' + count;
			div.className = 'carousel-entry fade';

			let link = null;

			if (output.clickable === true) {
				link = document.createElement('a');
				link.setAttribute('href', output.actionURL);
			}

			let image = document.createElement('img');
			image.setAttribute('src', output.sourceURL);
			image.setAttribute('alt', output.altTag !== '' ? output.altTag : output.title);


			if (output.clickable === true) {
				link.append(image);
				div.append(link);
			} else {
				div.append(image);
			}
			entries.push(div);
			count++;
		});
		for (let i = 0; i < count; i++) {
			target.append(radios[i]);
			target.append(preLabels[i]);
		}

		for (let i = 0; i < count; i++) {
			target.append(entries[i]);
		}

		for (let i = 0; i < count; i++) {
			target.append(postLabels[i]);
		}

		initScrollCarousel();

		setTimeout(() => {
			document.getElementById('carousel-0').checked = true;
		}, 1000);
	}
}
var activeTimeout = null;

function scrollCarousel() {
	var targetCarousel = document.getElementById('wrapper-carousel-entry');
	var radioButtons = targetCarousel.getElementsByTagName('input');

	if(radioButtons.length <= 1) {
		return true;
	}

	var activeRadio = null;
	var activeId = null;
	var maxId = null;

	for (var i = 0; i < radioButtons.length; i++) {
		if (radioButtons[i].type.toLowerCase() === 'radio') {
			if (radioButtons[i].checked) {
				activeRadio = radioButtons[i];
			}
		}
	}

	if(activeRadio !== null) {
		activeId = parseInt(activeRadio.id.substr(-1, 1));
		maxId = parseInt(radioButtons[radioButtons.length - 1].id.substr(-1, 1));

		if(activeId === maxId) {
			radioButtons[0].checked = true;
			activeRadio.checked = false;
		} else {
			radioButtons[activeId + 1].checked = true;
			activeRadio.checked = false;
		}
	}

	initScrollCarousel();
}

function initScrollCarousel() {
	activeTimeout = setTimeout(scrollCarousel, 8000);
}

function clearActiveCarouselTimeout() {
	clearTimeout(activeTimeout);
	initScrollCarousel();
}

function pullCMSProductPageContent() {
	if (document.getElementById('product-page-banner')) {
		var args = "type=PRODUCTBANNER&id=5";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updateProductPageBanner, "json");
	}
}

function updateProductPageBanner(response) {
	let target = document.getElementById('product-page-banner');
	let key = 0;

	if (target !== null) {
		if (response.length > 0 && typeof response[0].id !== 'undefined') {
			response.forEach((r) => {
				let wrapper = document.createElement('div');
				wrapper.id = 'product-page-banner-entry-' + key;
				wrapper.className = 'product-page-banner-entry';
				if (key !== 0) {
					wrapper.style.display = 'none';
				}
				key++;
				let link = null;

				if (r.clickable === true) {
					link = document.createElement('a');
					link.setAttribute('href', r.actionURL);
				}

				let image = document.createElement('img');
				image.setAttribute('src', r.sourceURL);
				image.setAttribute('alt', r.title);


				if (r.clickable === true) {
					link.append(image);
					wrapper.append(link);
				} else {
					wrapper.append(image);
				}
				target.append(wrapper);
			});

			setTimeout(animateProductBanner.bind(null, 'product'), 4000);
		}
	}
}

function pullCMSScartPageContent() {
	if (document.getElementById('scart-page-banner')) {
		var args = "type=BASKETBANNER&id=14";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updateScartPageBanner, "json");
	}
}

function updateScartPageBanner(response) {
	let target = document.getElementById('scart-page-banner');
	let key = 0;

	if (target !== null) {
		if (typeof response[0].id !== 'undefined') {
			response.forEach((r) => {
				let wrapper = document.createElement('div');
				wrapper.id = 'scart-page-banner-entry-' + key;
				wrapper.className = 'scart-page-banner-entry';
				if (key !== 0) {
					wrapper.style.display = 'none';
				}
				key++;
				let link = null;

				if (r.clickable === true) {
					link = document.createElement('a');
					link.setAttribute('href', r.actionURL);
				}

				let image = document.createElement('img');
				image.setAttribute('src', r.sourceURL);
				image.setAttribute('alt', r.title);


				if (r.clickable === true) {
					link.append(image);
					wrapper.append(link);
				} else {
					wrapper.append(image);
				}
				target.append(wrapper);
			});

			setTimeout(animateProductBanner.bind(null, 'scart'), 4000);
		}
	}
}

function pullCMSSearchPageContent() {
	if (document.getElementById('search-page-banner')) {
		var args = "type=SEARCHBANNER&id=13";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updateSearchPageBanner, "json");
	}
}

function updateSearchPageBanner(response) {
	let target = document.getElementById('search-page-banner');
	let key = 0;

	if (target !== null) {
		if (response.length > 0 && typeof response[0].id !== 'undefined') {
			response.forEach((r) => {
				let wrapper = document.createElement('div');
				wrapper.id = 'search-page-banner-entry-' + key;
				wrapper.className = 'search-page-banner-entry';
				if (key !== 0) {
					wrapper.style.display = 'none';
				}
				key++;
				let link = null;

				if (r.clickable === true) {
					link = document.createElement('a');
					link.setAttribute('href', r.actionURL);
				}

				let image = document.createElement('img');
				image.setAttribute('src', r.sourceURL);
				image.setAttribute('alt', r.title);


				if (r.clickable === true) {
					link.append(image);
					wrapper.append(link);
				} else {
					wrapper.append(image);
				}
				target.append(wrapper);
			});

			setTimeout(animateProductBanner.bind(null, 'search'), 4000);
		}
	}
}

function animateProductBanner(targetName) {
	let wrapper = document.getElementById( targetName +'-page-banner');
	let bannerEntries = wrapper.getElementsByClassName(targetName + '-page-banner-entry');
	let currentActiveBanner = null;
	let activeBanners = 0;
	[].forEach.call(bannerEntries, function(e) {
		activeBanners++;
		if (e.style.display !== 'none') {
			currentActiveBanner = e;
		}
	});

	if (activeBanners < 2) {
		return;
	}

	let key = parseInt(currentActiveBanner.id.slice(currentActiveBanner.id.length - 1));
	let newKey = 0;

	if (key < bannerEntries.length - 1) {
		newKey = key + 1;
		// Otherwise just leave at 0
	}

	let nextDiv = document.getElementById(targetName + '-page-banner-entry-' + newKey);
	let currentDiv = document.getElementById(targetName + '-page-banner-entry-' + key);

	wrapper.insertBefore(nextDiv, currentDiv);


	nextDiv.style.display = 'block';
	currentDiv.className += ' fade-out';
	setTimeout(function() {
		currentDiv.style.display = 'none';
		currentDiv.className = document.getElementById(targetName + '-page-banner-entry-' + key).className.replace(' fade-out', '');
	}, 500);

	setTimeout(animateProductBanner.bind(null, targetName), 4000);
}


function pullCMSPresellPageContent() {
	if (document.getElementById('preorder-page-banner')) {
		var args = "type=PRESELLBANNER&id=12";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePresellPageBanner, "json");
	}
}

function updatePresellPageBanner(response) {
	let target = document.getElementById('preorder-page-banner');
	let key = 0;

	if (target !== null) {
		if (response.length > 0 && typeof response[0].id !== 'undefined') {
			response.forEach((r) => {
				let wrapper = document.createElement('div');
				wrapper.id = 'preorder-page-banner-entry-' + key;
				wrapper.className = 'preorder-page-banner-entry';
				if (key !== 0) {
					wrapper.style.display = 'none';
				}
				key++;
				let link = null;

				if (r.clickable === true) {
					link = document.createElement('a');
					link.setAttribute('href', r.actionURL);
				}

				let image = document.createElement('img');
				image.setAttribute('src', r.sourceURL);
				image.setAttribute('alt', r.title);


				if (r.clickable === true) {
					link.append(image);
					wrapper.append(link);
				} else {
					wrapper.append(image);
				}
				target.append(wrapper);
			});

			if (response.length > 1) {
				setTimeout(animatePreorderBanner, 4000);
			}
		}
	}
}

function animatePreorderBanner() {
	let wrapper = document.getElementById('preorder-page-banner');
	let bannerEntries = wrapper.getElementsByClassName('preorder-page-banner-entry');
	let currentActiveBanner = null;

	let activeBanners = 0;
	[].forEach.call(bannerEntries, function(e) {
		activeBanners++;
		if (e.style.display !== 'none') {
			currentActiveBanner = e;
		}
	});

	if (activeBanners < 2) {
		return;
	}

	let key = parseInt(currentActiveBanner.id.slice(currentActiveBanner.id.length - 1));
	let newKey = 0;

	if (key < bannerEntries.length - 1) {
		newKey = key + 1;
		// Otherwise just leave at 0
	}

	let nextDiv = document.getElementById('preorder-page-banner-entry-' + newKey);
	let currentDiv = document.getElementById('preorder-page-banner-entry-' + key);

	wrapper.insertBefore(nextDiv, currentDiv);


	nextDiv.style.display = 'block';
	currentDiv.className += ' fade-out';
	setTimeout(function() {
		currentDiv.style.display = 'none';
		currentDiv.className = document.getElementById('preorder-page-banner-entry-' + key).className.replace(' fade-out', '');
	}, 500);

	setTimeout(animatePreorderBanner, 4000);
}


function pullCMSSMBanner6Content() {
	if (document.getElementById('page-banner-6')) {
		var args = "type=SUBBANNER1&id=6";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSSMBanner7Content() {
	if (document.getElementById('page-banner-7')) {
		var args = "type=SUBBANNER1&id=7";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSBanner2Content() {
	if (document.getElementById('page-banner-2')) {
		var args = "type=SUBBANNER1&id=2";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSBanner3Content() {
	if (document.getElementById('page-banner-3')) {
		var args = "type=SUBBANNER1&id=3";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSSMBanner8Content() {
	if (document.getElementById('page-banner-8')) {
		var args = "type=SUBBANNER3&id=8";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSSMBanner9Content() {
	if (document.getElementById('page-banner-9')) {
		var args = "type=SUBBANNER3&id=9";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSSMBanner10Content() {
	if (document.getElementById('page-banner-10')) {
		var args = "type=SUBBANNER1&id=10";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}

function pullCMSSMBanner11Content() {
	if (document.getElementById('page-banner-11')) {
		var args = "type=SUBBANNER1&id=11";
		new AjaxRequest("GET", "/ajax/CMS/get_content.php", args, updatePageBannerResponse, "json");
	}
}


function updatePageBannerResponse(response) {
	// We should only have one banner for this content, but the response is in an array so we have to grab the first one only
	response = response[0];
	let target = document.getElementById('page-banner-' + response.elementId);

	if (target !== null) {
		while (target.firstChild) {
			target.removeChild(target.firstChild);
		}

		if (typeof response !== 'undefined' && response !== [] && typeof response.sourceURL !== 'undefined') {
			let link = null;

			if (response.clickable == true) {
				link = document.createElement('a');
				link.setAttribute('href', response.actionURL);
			}

			let image = document.createElement('img');
			image.setAttribute('src', response.sourceURL);
			image.setAttribute('alt', response.title);

			target.style.background = '#FFF';

			if (response.clickable == true) {
				link.append(image);
				target.append(link);
			} else {
				target.append(image);
			}
		} else {
			target.style.background = '#FFF';
			target.style.minHeight = '0';
		}
	}
}

window.addEventListener("load", pullLoginBanner);
window.addEventListener("load", pullCMSBanner3Content);
window.addEventListener("load", pullCMSBanner2Content);
window.addEventListener("load", pullCMSSMBanner10Content);
window.addEventListener("load", pullCMSSMBanner11Content);
window.addEventListener("load", pullCMSSMBanner6Content);
window.addEventListener("load", pullCMSSMBanner7Content);
window.addEventListener("load", pullCMSSMBanner8Content);
window.addEventListener("load", pullCMSSMBanner9Content);
window.addEventListener("load", pullCMSProductPageContent);
window.addEventListener("load", pullCMSSearchPageContent);
window.addEventListener("load", pullCMSScartPageContent);
window.addEventListener("load", pullCMSPresellPageContent);
window.addEventListener("load", pullCMSContent);

window.addEventListener('click', clearActiveCarouselTimeout);

