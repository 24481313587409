function closeEveryMenu() {
	var shopButton = document.getElementById('shop-menu-button-wrapper').getElementsByTagName('a')[0];
	shopButton.style.backgroundColor = '';
	shopButton.style.color = '';
	shopButton.className = '';

	// Remember to add ANYTHING you want to close when the X is pressed!
	hideProductHierachyMenu(document.getElementById('product-links-page-overlay'));
}

function goBackProductHierarchy() {
	var productHierarchyWrapper = document.getElementById('product-links-wrapper');
	var previousEle = null;
	[].forEach.call(productHierarchyWrapper.children, function (ele) {
		if (ele.id !== 'product-links-mobile-controls') {
			if (ele.style.display !== 'none') {
				if (ele.id === 'product-links-level-0') {
					hideProductHierachyMenu(document.getElementById('product-links-page-overlay'));
				} else {
					previousEle.style.display = 'block';
					ele.style.display = 'none';
				}
				return;
			}
			previousEle = ele;
		}
	});
}

function hideProductHierachyMenu(wrapper) {
	wrapper.style.display = 'none';

	var productMenuWrapper = document.getElementById('product-links-wrapper');
	var firstMenuColumn = document.getElementById('product-links-level-0');

	// This will iterate over each item in the first level menu, but we need to drill down to the inner a elements too so we can remove any bold
	[].forEach.call(firstMenuColumn.children, function (parentEle) {
		[].forEach.call(parentEle.children, function (innerEle) {
			innerEle.style.fontWeight = 100;
		});
	});


	// Clean-up the menu for next use
	[].forEach.call(productMenuWrapper.children, function(child) {
		// Leave the first element and the controls, but otherwise...
		if (child.id !== 'product-links-level-0' && child.id !== 'product-links-mobile-controls') {
			while(child.firstChild) {
				child.removeChild(child.firstChild);
			}

			child.style.display = 'none';
		}
	});
}

function showProductHierarchyMenu(button) {
	var overlay = document.getElementById('product-links-page-overlay');
	if (overlay.style.display !== 'block') {
		button.style.backgroundColor = '#FFF';
		button.style.color = '#000';
		button.className = 'currently-selected-shop-menu';

		// Note - using offsets does not work, they always return 0, so instead we calculate the height of the header
		var header = document.getElementById('header');
		var mainMenu = document.getElementById('main_menu_bar');
		var viewportOffset = header.offsetHeight + mainMenu.offsetHeight - 2;
		var overlayHeight = window.innerHeight - viewportOffset;

		overlay.style.height = overlayHeight.toString() + 'px';
		overlay.style.display = 'block';

		document.getElementById('product-links-level-0').style.display = 'block';
	} else {
		closeEveryMenu();
	}
}

function getNextProductHierarchyLevel(event, object, currentLevel, productGroupId) {
	var targetDiv = document.getElementById('product-links-level-' + currentLevel);
	[].forEach.call(object.parentNode.parentNode.children, function(child) {
		child.style.fontWeight = 100;
		[].forEach.call(child.children, function(ele) {
			ele.style.fontWeight = 100;
		});
	});
	object.style.fontWeight = 700;
	if (object.nextElementSibling !== null) {
		object.nextElementSibling.style.fontWeight = 700;
	} else {
		object.previousElementSibling.style.fontWeight = 700;
	}

	new AjaxRequest("GET", "/ajax/menu/product_groups.php",
		"product_group=" + productGroupId + "&menu_level=" + (currentLevel + 1), displayNextProductLevel, "json");
}

function displayNextProductLevel(response) {
	var mobileLayout = (window.innerWidth < 859);
	if (response[0].empty_product_level === true) {
		var targetDiv = document.getElementById('product-links-level-' + response[0].menu_level);
		if (targetDiv !== null) {
			while (targetDiv.firstChild) {
				targetDiv.removeChild(targetDiv.firstChild);
			}
			targetDiv.style.display = 'none';
		}
		return;
	}

	if (mobileLayout) {
		document.getElementById('product-links-level-' + (response[0].menu_level - 1)).style.display = 'none';
	}

	var targetDiv = document.getElementById('product-links-level-' + response[0].menu_level);

	if (targetDiv !== null) {
		while (targetDiv.firstChild) {
			targetDiv.removeChild(targetDiv.firstChild);
		}
		targetDiv.style.display = 'block';
	} else {
		var menuWrapper = document.getElementById('product-links-wrapper');
		var targetDiv = document.createElement('div');
		targetDiv.id = 'product-links-level-' + response[0].menu_level;
		targetDiv.setAttribute('onclick', "event.stopPropagation();");
		menuWrapper.appendChild(targetDiv);
	}

	var divCounter = parseInt(response[0].menu_level);

	var nextLevel = document.getElementById('product-links-level-' + (++divCounter));

	while(nextLevel !== null) {
		nextLevel.parentNode.removeChild(nextLevel);
		nextLevel = document.getElementById('product-links-level-' + (++divCounter));
	}
	//<div>
		//<a href="/products/?mid=<?= $product_menu_item ?>">
			//View all
		//</a>
	//</div>
	var linkHolder = document.createElement('div');
	var newLink = document.createElement('a');
	var productsMenuId = document.getElementById('products_menu_id').value;
	newLink.setAttribute('href', "/products/?mid=" + productsMenuId + "&prdgrp=" + response[0].parent_group_id);
	newLink.textContent = 'View All';
	newLink.className = 'product-links-view-all';
	linkHolder.appendChild(newLink);
	targetDiv.appendChild(linkHolder);
	targetDiv.appendChild(document.createElement('hr'));


	response.forEach(function(entry) {
		if(typeof entry.group_id !== 'undefined') {
			linkHolder = document.createElement('div');
			newLink = document.createElement('a');
			productsMenuId = document.getElementById('products_menu_id').value;
			if (entry.child_count > 0) {
				newLink.setAttribute('onclick', "getNextProductHierarchyLevel(event, this, " + response[0].menu_level + ", '" + entry.group_id + "');event.stopPropagation();");
			} else {
				newLink.setAttribute('href', "/products/?mid=" + productsMenuId + "&prdgrp=" + entry.group_id);
			}
			newLink.textContent = entry.group_description.toLocaleLowerCase().replace(/\b[a-z]/g, function(letter) {
				return letter.toUpperCase();
			})

			if (entry.child_count > 0) {
				var newLinkArrow = document.createElement('a');
				newLinkArrow.setAttribute('onclick', "getNextProductHierarchyLevel(event, this, "+ response[0].menu_level +", '" + entry.group_id + "');event.stopPropagation();");
				newLinkArrow.textContent = ">";
				newLinkArrow.className = 'product-links-product-arrow';
				linkHolder.appendChild(newLinkArrow);
			}
			linkHolder.appendChild(newLink);
			targetDiv.appendChild(linkHolder);
		}
	});
}